import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import ModalForm from './ModalForm.js';
import useRequest from './../hooks/RequestHook.js'
const TablaRemates = ({ records, recordsTable, recordsExcel, loading,reloadAction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  const {updateAuction} = useRequest();
  const handleOpen = (index) => {
    let newData = Object.assign({}, records[index]);
    delete newData.created_at;
    delete newData.updated_at;
    console.log(newData);
    setData(newData);
    
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = async (updatedData) => {
    console.log('Datos guardados:', updatedData);
    let id = updatedData.id;
    delete updatedData.id;
    /*
    Object.keys(updatedData).map(e=>{
      if(updatedData[e]=="")
        delete updatedData[e]
    })
    */
    let data = Object.assign({}, updatedData);
    let response = await updateAuction(id, data);
    if(response?.id){
      reloadAction();
    }

  };

  const handleNumbers =(n) =>{
    return n.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(recordsExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'recordsExcel');
    
    // Generar el archivo Excel
    XLSX.writeFile(workbook, 'recordsExcel.xlsx');
  };

  if (loading) {
    return <p>Estan cargando los datos</p>;
  }

  if (!recordsExcel || recordsExcel.length === 0) {
    return <p>No hay registro disponibles.</p>;
  }

  return (
    <div>
      <div className='grid grid-cols-2 gap-4 my-5'>
        <button 
          onClick={exportToExcel} 
          className="mb-4 bg-green-500 text-white p-2 rounded hover:bg-green-600"
        >
          Exportar a Excel
        </button>
        {
          records[records.length-1].created_at&&
          <label className='font-bold w-full'>
            ultima actualizacion {records[records.length-1].created_at}
          </label>
        }
      </div>
   
     
      <table className="min-w-full border-collapse border border-blue-950">
        <thead>
          <tr className="bg-gray-200">
            {Object.keys(recordsTable[0]).map((key) => (
              <th key={key} className="border border-blue-950 p-2">{key}</th>
            ))}
          </tr>

        </thead>
        <tbody>
          {recordsTable.map((registro, index) => (
            <tr key={index} className={`${records[index]?.favorite_record == 1?'bg-slate-300':'hover:bg-gray-100'}`}>
              {Object.values(registro).map((value, idx) => {
                console.log(`value: ${value} idx: ${idx}`)
                 return ([8].includes(idx))?
                 <td key={idx} className="border border-blue-950 p-2 text-right">{handleNumbers(value)}</td>
                 :
                 <td key={idx} className="border border-blue-950 p-2 text-center">{value}</td>
              }
               
              )}
              {
                records[index]?.id?
                <>
                  <td className='border border-blue-950 p-2'>
                    <div className='w-full flex flex-inline'>
                    <button 
                    onClick={()=>handleOpen(index)}
                    type="button" 
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                    <span className="sr-only">Icon description</span>
                    </button>
                    </div>
                  </td>
                </>
                :null
              }
            </tr>
          ))}
        </tbody>
      </table>
        <div className='w-full'>
          <ModalForm
            isOpen={isOpen}
            onClose={handleClose}
            data={data}
            onSave={handleSave}

          />
        </div>
      
    </div>
  );
};

export default TablaRemates;